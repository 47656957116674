import cloudflare from './cloudflare';
import firebase from './firebase';

export default {
  failed: 'Action failed',
  success: 'Action was successful',

  error: {
    undefined: 'Undefined error occurred',
    validator: {
      mandatory: 'Mandatory field',
      email: 'Not a valid email format.',
      length: 'Length must between {min} and {max}',
      range: 'Range must between {min} and {max}',
      notDate: 'Not a date.',
    },
    type: {
      undefined: 'Unknown error',
      GENERIC_ERROR: 'Generic backend error',
      VALIDATION_ERROR: 'Validation error',
    },
    errorCode: {
      ...firebase,
      ...cloudflare,
    },
  },

  menu: {
    invite: 'Invite',
    users: 'Users',
    logout: 'Log out',
  },

  common: {
    password: 'Password',
    submit: 'Submit',
    login: 'Login',
    send: 'Send',
    email: 'Email',
  },

  login: {
    email: 'Usernam or email',
  },

  invite: {
    header: 'Invite administrator',
    body: "Register a new administrator. We will send notification email as well to verify its identity. Don't forget to ask client certificate as well.",
  },
};
