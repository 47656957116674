import { boot } from 'quasar/wrappers';

import { inject, InjectionKey } from 'vue';
import { api } from './axios';
import {
  RefreshTokenRequest,
  RefreshTokenResponse,
  UserPassLoginRequest,
  LoginResponse,
  FUNCTION_URL_LOGIN,
  FUNCTION_URL_REFRESH_TOKEN,
} from 'src/classes/Cloudflare';
import { User } from 'src/classes/Firebase';
import { storageService } from './storage';
import * as jose from 'jose';

export const authServiceSymbol = Symbol(
  'authService'
) as InjectionKey<AuthService>;

export interface AuthService {
  signIn(
    username: string,
    password: string,
    turnstileToken: string
  ): Promise<User>;

  getCurrentUser(): Promise<User | undefined>;

  refreshToken(user: User): Promise<User>;
  logout(): Promise<void>;
}

const USER_STORAGE_KEY = 'user';

const getCurrentUser = async (): Promise<User | undefined> => {
  return new Promise(async (resolve) => {
    const user: User | null = await storageService.getItem(USER_STORAGE_KEY);
    if (user) {
      resolve(user);
    } else {
      resolve(undefined);
    }
  });
};

const logout = (): Promise<void> => {
  return storageService.removeItem(USER_STORAGE_KEY);
};

const refreshToken = async (user: User): Promise<User> => {
  const body: RefreshTokenRequest = {
    refreshToken: user.refreshToken,
    userId: user.localId,
  };
  const result: RefreshTokenResponse = (
    await api.post(FUNCTION_URL_REFRESH_TOKEN, body)
  ).data;

  const newUser: User = {
    ...user,
    refreshToken: result.refreshToken,
    idToken: result.idToken,
  };

  storageService.setItem(USER_STORAGE_KEY, newUser);
  return Promise.resolve(newUser);
};

const signIn = async (
  username: string,
  password: string,
  turnstileToken: string
): Promise<User> => {
  return new Promise((resolve, reject) => {
    const loginRequest: UserPassLoginRequest = {
      username,
      password,
      turnstileToken,
    };

    api
      .post(FUNCTION_URL_LOGIN, loginRequest)
      .then((r) => r.data)
      .then((loginResponse: LoginResponse) => {
        const result: User = {
          idToken: loginResponse.idToken,
          displayName: loginResponse.displayName,
          refreshToken: loginResponse.refreshToken,
          email: loginResponse.email,
          photoURL: loginResponse.profilePicture,
          localId: loginResponse.localId,
        };

        storageService.setItem(USER_STORAGE_KEY, result);
        resolve(result);
      })
      .catch((e) => reject(e.data));
  });
};

const authService: AuthService = {
  signIn,
  getCurrentUser,
  logout,
  refreshToken,
};

export default boot(async ({ app }) => {
  const user: User | null = await storageService.getItem(USER_STORAGE_KEY);

  if (user) {
    const now = new Date().getTime();
    const jwt = jose.decodeJwt(user.idToken);
    if (jwt.exp && jwt.exp * 1000 < now) {
      await refreshToken(user);
    }
  }

  app.provide(authServiceSymbol, authService);
});

export function useAuth(): AuthService | undefined {
  return inject(authServiceSymbol);
}
